import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('test-element')
export class TestElement extends LitElement {
    override render() {
        return html`
          <h1>TestElement oi!</h1>
          <slot></slot>
        `;
    }
};

declare global {
    interface HTMLElementTagNameMap {
        'test-element': TestElement;
    }
};