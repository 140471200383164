import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ISearchEventDetail } from './SearchForm';
import { ISearchResultDetail } from './SearchResults';

import { search } from './133x';

@customElement('search-app')
export class SearchApp extends LitElement {
    protected override createRenderRoot(): HTMLElement | DocumentFragment {
        return this;
    }

    private _results: Array<ISearchResultDetail> = [];

    async _searchHandler(e: CustomEvent<ISearchEventDetail>) {
        this._results = await search(e.detail.term);
        this.requestUpdate();

    }
    override render() {
        return html`
        <div>
          <div>
            <h1>Search App</h1>
            <search-form @search=${this._searchHandler}></search-form>
            <search-results results=${JSON.stringify(this._results)}></search-results>
          </div>
        </div>
        `;
    }
};

declare global {
    interface HTMLElementTagNameMap {
        'search-app': SearchApp;
    }
};