

type TSearchResults = {
    title: string;
    path: string;
    seeds: number;
    leeches: number;
    // size: string;
}

const fetchOpts = {
    credentials: 'omit' as RequestCredentials,

    referrer: ''
}

export function torrentMagnet(torrentPath: string): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
        const httpResp = await fetch(`https://corsproxy.io/?https://1337x.to${torrentPath}`);
        const text = (await httpResp.text());
        const p = new DOMParser();
        const doc = p.parseFromString(text, "text/html");
        console.log('doc');
        console.log(doc);
        const aaas = doc.getElementsByTagName('a');
        // console.dir(aaas);
        let magnets: Array<HTMLAnchorElement> = [];
        // const magnets = aaas.
        for(const a of aaas) {
            if (a.href.startsWith('magnet:') && a.text === 'Magnet Download') {
                magnets.push(a);
            }
        }
        if (magnets.length === 0) return reject(new Error('no magnet link found'));
        resolve(magnets[0].href);
    });
}

export function search(term: string): Promise<Array<TSearchResults>> {
    return new Promise<Array<TSearchResults>>(async (resolve, _reject) => {
        // console.log('Do search for ' + term);
        const results: Array<TSearchResults> = [];
        const httpResp = await fetch(`https://corsproxy.io/?https://1337x.to/search/${term}/1/`, fetchOpts);
        const text = (await httpResp.text());
        // const doc = cheerio.load(text);
        const p = new DOMParser();
        const doc = p.parseFromString(text, "text/html");
        const tbodys = doc.getElementsByTagName('tbody');
        if (tbodys.length > 0) {
            const tbody = tbodys[0];
            const trs = tbody.getElementsByTagName('tr');
            // console.log(trs[0]);
            for(const tr of trs) {
                if (tr.cells.length > 4) {
                    // console.log(tr);
                    const titleLink = tr.cells.item(0) as HTMLTableCellElement;
                    const aaas = titleLink.getElementsByTagName('a');
                    const href = (aaas.length > 1) ? (new URL(aaas[1].href)).pathname : '';
                    const seeds = parseInt((tr.cells.item(1) as HTMLTableCellElement).textContent as string, 10);
                    const leeches = parseInt((tr.cells.item(2) as HTMLTableCellElement).textContent as string, 10);
                    // const size = (tr.cells.item(4) as HTMLTableCellElement).textContent as string;
                    results.push({
                        title: titleLink.textContent as string,
                        path: href,
                        seeds,
                        leeches,
                        // size
                    })
                }
            }
        
        }
        // console.log('doc');
        // console.log(doc);
        /* const fakeWin = new Window({ url: `https://1337x.to/search/${term}/1` });
        const doc = fakeWin.document;

        console.log(doc.body.innerHTML); */
        resolve(results);
        /* try {
            // const httpResp = await axios.get(`https://1337x.to/search/${term}/1/`);
            // console.log(httpResp);
            const text = (await httpResp.text());
            // const blob = (await httpResp.blob());
            const dom = parseDocument(text);
            //const children = dom.children;
            const results = extractSearchResults(dom);

            console.dir({
                // headers: httpResp.headers,
                // blob,
                // text,
                results
            });
            // console.log(dom);
            resolve([]);

        } catch (e) {
            return reject(e);
        } */
    });
}