import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

export interface ISearchEventDetail {
    term: string;
};

@customElement('search-form')
export class SearchForm extends LitElement {
    protected override createRenderRoot(): HTMLElement | DocumentFragment {
        return this;
    }

    private _searchTerm: string = '';

    _inputChanged(e: Event) {
        this._searchTerm = (e.target as HTMLInputElement).value;
    }

    _keyDown(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this._searchTerm = (e.target as HTMLInputElement).value;
            this._emitSearch();
        }
    }

    _buttonClicked(e: MouseEvent) {
        e.preventDefault();
        this._emitSearch();
    }

    _emitSearch() {
        const event = new CustomEvent('search', { bubbles: true, composed: true, detail: { term: this._searchTerm } });
        this.dispatchEvent(event);
    }

    override render() {
        return html`
        <form>
            <fieldset class="border-solid border-2 border-sky-500 m-4">
                <legend>Search</legend>
                    <input class="block w-250 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" @input=${this._inputChanged} @keydown=${this._keyDown}>
                    <button class="block w-150 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" @click=${this._buttonClicked}>Go</button>
            </fieldset>
        </form>
        `;
    }
};

declare global {
    interface HTMLElementTagNameMap {
        'search-form': SearchForm;
    }
};