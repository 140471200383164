import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { torrentMagnet } from './133x';

export interface ISearchResultDetail {
    title: string;
    path: string;

    seeds: number;
    leeches: number;
};

@customElement('search-results')
export class SearchResults extends LitElement {

    @property({
        converter: (from) => {
            if (from === null) return [];
            return (JSON.parse(from as string) as Array<ISearchResultDetail>)
        }
    })
    private results = new Array<ISearchResultDetail>();

    private async _clickHandler(e: Event) {
        e.preventDefault();
        const ele = (e.target as HTMLAnchorElement);
        const path = (new URL(ele.href)).pathname;


        const magnet = await torrentMagnet(path);
        window.open(magnet, 'magnetframe');
    }

    override render() {
        return html`
          <iframe style="display:none" name="magnetframe"></iframe>
          ${this.results.length === 0 ? null : html`
          <h1>Results:</h1>
          <table class="pure-table pure-table-horizontal">
            <thead>
                <tr>
                    <th>Seeds</th>
                    <th>Leechers</th>
                    <th>Title/Link</th>
                </tr>
            </thead>
            <tbody>
            ${this.results.map((result) =>
            html`<tr><td>${result.seeds}</td><td>${result.leeches}</td><td><a href='${result.path}' @click=${this._clickHandler}>${result.title}</a></td></tr>`
        )}
            </tbody>
          </table>
        `}
        `;
    }
};

declare global {
    interface HTMLElementTagNameMap {
        'search-results': SearchResults;
    }
};